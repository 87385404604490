import React from "react";
import { Box, Toolbar, Grid } from '@mui/material';
import AppBar from '@mui/material/AppBar';


const Footer = ({ pageElements }) => {
    return (
        <AppBar elevation={0} sx={{ background: "rgb(248,249,252)", top: 'auto', bottom: 0 }} >
            <Toolbar sx={{ justifyContent: "center" }}>
                <Box component="img" height="48px" sx={{ padding: '8px' }}
                    src={pageElements.mercury_logo}
                />
            </Toolbar>
        </AppBar>
    );
}


const Header = ({ pageElements }) => {
    return (
        <Grid container columns={{ xs: 4, sm: 8, md: 12 }} justifyContent="center" alignItems="center"  >
            <Box display="flex" justifyContent="center" flexDirection="column" sx={{
                /*
                width: {
                    sm: '100%', // theme.breakpoints.up('sm')
                    lg: '100%', // theme.breakpoints.up('lg')
                    xl: '100%', // theme.breakpoints.up('xl')
                }
                */
            }} component="img" src={pageElements.banner} />
        </Grid>
    );
}


const MercuryResponsivePage = ({ pageElements, children, hideFooter }) => {
    return (
        <Box display="flex" justifyContent="center" flexDirection="column" sx={{ background: "rgb(248,249,252)" }}>
            <Box sx={{ /*padding: "22px"*/ }} flex={1} justifyContent="center" flexDirection="column" display="flex">
                <Header pageElements={pageElements} />
                <Grid container columns={{ xs: 4, sm: 8, md: 12 }} justifyContent="center" alignItems="center"  >
                    <Box display="flex" justifyContent="center" flexDirection="column" sx={{
                        width: {
                            xs: 340, // theme.breakpoints.up('xs')
                            sm: 600, // theme.breakpoints.up('sm')
                            lg: 700, // theme.breakpoints.up('lg')
                            xl: 800, // theme.breakpoints.up('xl')
                        }
                    }}>
                        {children}
                    </Box>
                </Grid>
                {!hideFooter && <Footer pageElements={pageElements} />}
            </Box>
        </Box>
    );
}

export default MercuryResponsivePage;