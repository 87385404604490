import React from "react";
import { useState, useCallback, useEffect } from 'react';

import 'survey-core/defaultV2.min.css';
import { Model } from 'survey-core';
import { Survey } from 'survey-react-ui';
import { sendRegistrationOnQueue } from "../../aws/lambda/DBManager";
import stripePayments from "./widgets/StripePayments";

// const SURVEY_ID = 1;
/*
function escapeSpecialCharFromJsonData(jsonData) {

  var newJsonData = {};

  for (var key in jsonData) {
      //catturo il valore della chiave
      let value = jsonData[key];
      //filtro il valore della chiave facendo l'escape dei caratteri speciali
      //filtro il +
      value = value.replace("+", "%2B");
      //filtro la &
      value = value.replace("&", "and");
      //filtro il %
      //value = value.replace("%", "_percentsign_");
      newJsonData = { ...newJsonData, [key]: value };
  }
  

  return newJsonData;
}
  */

function escapeSpecialCharFromJsonData(jsonData) {
  const newJsonData = {};

  for (const key in jsonData) {
    let value = jsonData[key];

    if (typeof value === 'string') {
      // Fai l'escape dei caratteri speciali
      value = value.replace(/\+/g, "%2B");
      value = value.replace(/&/g, "and");
    }
    
    newJsonData[key] = value;
  }

  return newJsonData;
}


const sendRegistration = (eventID, jsonResponseData) => {


  let registrationJSON = { "command": "registration", "item": {} };
  //let itemData = { "eventId": eventID };

  let itemData = { ...jsonResponseData, ["eventId"]: eventID };
  registrationJSON.item = escapeSpecialCharFromJsonData(itemData);
  //console.log(registrationJSON)


      //registrationJSON.item = itemData;
    console.log("REGDATA: " + registrationJSON);
    sendRegistrationOnQueue(registrationJSON);
  }




function findTag(domandaName, eventoJson) {
  for (const page of eventoJson.pages) {
    for (const element of page.elements) {
      if (element.name === domandaName) {
        return element;
      }
    }
  }
  return null; 
}

function removeDuplicateEmailsAndRename(json) {
  const seenEmails = new Set();
  const uniqueJson = {};

  for (const key in json) {
      const value = json[key];
      if (!seenEmails.has(value)) {
          seenEmails.add(value);
          if (value.includes('@')) {
              uniqueJson["email"] = value;
          } else {
              uniqueJson[key] = value;
          }
      }
  }

  return uniqueJson;
}


function findByTag(array, tag) {
  return array.find(item => item.tag === tag);
}

function SurveyJSVisualizer({surveyJson, eventID}) {

  useEffect(() => {
    const handleCopy = (event) => {
      alert("La funzione copia/incolla non è permessa")
      event.preventDefault();
    };

    const handleCut = (event) => {
      alert("La funzione copia/incolla non è permessa")
      event.preventDefault();
    };

    const handlePaste = (event) => {
      alert("La funzione copia/incolla non è permessa")
      event.preventDefault();
    };

    const handleKeyDown = (event) => {
      if ((event.ctrlKey || event.metaKey) && (event.key === 'c' || event.key === 'v' || event.key === 'x')) {
        alert("La funzione copia/incolla non è permessa")
        event.preventDefault();
      }
    };

    document.addEventListener('copy', handleCopy);
    document.addEventListener('cut', handleCut);
    document.addEventListener('paste', handlePaste);
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('copy', handleCopy);
      document.removeEventListener('cut', handleCut);
      document.removeEventListener('paste', handlePaste);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  
  const survey = new Model(surveyJson);
  //console.log(surveyJson)
  const alertResults = useCallback((sender) => {
    const results = JSON.stringify(sender.data);
    //console.log(eventID);
    //console.log(results)
    //console.log(JSON.stringify(surveyJson));

    const newJson = {};
      for (const domandaName in sender.data) {
        //console.log(domandaName)
        const element = findTag(domandaName, surveyJson);
        if (element && element.tag) {
            newJson[element.tag] = sender.data[domandaName];
        }
      }


    let newJsonEmEdit = removeDuplicateEmailsAndRename(newJson)
    //console.log(newJsonEmEdit)
    sendRegistration(eventID, newJsonEmEdit);

    // saveSurveyResults(
    //   "https://your-web-service.com/" + SURVEY_ID,
    //   sender.data
    // )


  }, [surveyJson]);

  survey.onComplete.add(alertResults);

  return (
  <div className="survey-creator-container">
    <Survey model={survey} />
    </div>
  )
}


export default SurveyJSVisualizer;