import './App.css';
import Home from './pages/Home';
import { Routes, Route } from "react-router-dom";
import RequireEventID from './requirements/RequireEventID';
import React from 'react';

let defaultTemplateElements = {
  mercury_logo: "/images/static/MERCURYSuite_DigitalEvents.png",
  logo: "",
}

function App() {
  const [guiComponentData, setGuiComponentData] = React.useState(defaultTemplateElements);
  const [pageTemplateData, setPageTemplateData] = React.useState({});
  const [eventData, setEventData] = React.useState({});

  return (
    <div className="App">
      <RequireEventID templateData={pageTemplateData} setTemplateData={setPageTemplateData} setEventData={setEventData} templateGuiElements={guiComponentData} setTemplateGuiElements={setGuiComponentData}>
          <Routes>
            <Route path="/" element={
              <Home guiComponentData={guiComponentData} pageTemplateData={pageTemplateData} eventData={eventData} />
            } />
          </Routes>
      </RequireEventID>
    </div>
  );
}

export default App;