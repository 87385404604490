/*
import MercuryResponsivePage from "../components/MercuryResponsivePage";
import React from "react";
import { Typography, Box, Button } from "@mui/material";
import MercuryBasicFieldsForm from "../components/MercuryBasicFieldsForm";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { sendRegistrationOnQueue } from "../aws/lambda/DBManager";
import { useEffect } from "react";

function validateEmail(email) {
    let reg = /^([A-Za-z0-9_\-\.\+])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    if (reg.test(email) === false)
        return false;
    else return true;
}


function escapeSpecialCharFromJsonData(jsonData) {

    var newJsonData = {};

    for (var key in jsonData) {
        //catturo il valore della chiave
        let value = jsonData[key];
        //filtro il valore della chiave facendo l'escape dei caratteri speciali
        //filtro il +
        value = value.replace("+", "%2B");
        //filtro la &
        value = value.replace("&", "and");
        //filtro il %
        //value = value.replace("%", "_percentsign_");
        newJsonData = { ...newJsonData, [key]: value };
    }

    return newJsonData;
}


const Home = ({ guiComponentData, pageTemplateData, eventData }) => {
    const [sendRegistrationDisabled, setSendRegistrationDisabled] = React.useState(true);
    const [guiRegistrationStatus, setGuiRegistrationStatus] = React.useState(false);


    //cambio stato registrazioni
    const [openedRegistrations, setOpenedRegistrations] = React.useState(false);
    const [unopenedRegistrations, setUnopenedRegistrations] = React.useState(false);
    const [closedRegistrations, setClosedRegistrations] = React.useState(false);


    const onAccepted = (e) => {
        if (e === true) {
            setSendRegistrationDisabled(false);
        } else {
            setSendRegistrationDisabled(true);
        }
    }


    useEffect(() => {
        const intervalId = setInterval(() => {
            let currentDate = Date.now();

            //se la data corrente è maggiore della data inizio evento mostra la registrazioni
            if (currentDate >= eventData.startRegistrationDate && currentDate <= eventData.endRegistrationDate) {
                setOpenedRegistrations(true);
                setUnopenedRegistrations(false);
                setClosedRegistrations(false);
            } else if (currentDate < eventData.startRegistrationDate) { //viceversa mostra il messaggio di registrazioni non ancora aperte
                setOpenedRegistrations(false);
                setUnopenedRegistrations(true);
                setClosedRegistrations(false);
            }
            //se la data corrente è maggiore della fine dell'evento mostra messaggio chiusura registrazioni
            else if (currentDate > eventData.endRegistrationDate) {
                setOpenedRegistrations(false);
                setUnopenedRegistrations(false);
                setClosedRegistrations(true);
            }
        }, 1000);
        return () => { clearInterval(intervalId); };
    }, [eventData]);


    const sendRegistration = () => {
        let validation = true;
        let registrationData = pageTemplateData.formField;
        let registrationJSON = { "command": "registration", "item": {} };
        let itemData = { "eventId": eventData.id };
        let email = "";

        //prende i dati di registrazione e cattura i campi inseriti
        for (let i = 0; i < registrationData.length; i++) {
            let object = registrationData[i];

            //se è richiesto il testo, controllo se il testo è stato inserito
            document.getElementById(object.name).style.backgroundColor = "transparent";
            document.getElementById(object.name + "_errorlabel").parentNode.style.display = 'none';

            //cattura il testo inserito nell'oggetto
            if (object.type === "text" && (object.className === "name" || object.className === "surname" || object.className === "email" || object.className === "emailConfirm")) {
                let value = document.getElementById(object.name).value;

                if (object.required === true && value.length === 0) {
                    document.getElementById(object.name).style.backgroundColor = 'rgb(255,0,0,0.6)';
                    document.getElementById(object.name + "_errorlabel").style.color = 'red';
                    document.getElementById(object.name + "_errorlabel").parentNode.style.display = 'block';
                    document.getElementById(object.name + "_errorlabel").textContent = 'This field cannot be empty';
                    validation = false;
                } else {
                    //se il campo è email valido l'indirizzo
                    if ((object.className === "email" || object.className === "emailConfirm") && value.length > 0) {
                        if (!validateEmail(value)) {
                            document.getElementById(object.name).style.backgroundColor = 'rgb(255,0,0,0.6)';
                            document.getElementById(object.name + "_errorlabel").style.color = 'red';
                            document.getElementById(object.name + "_errorlabel").parentNode.style.display = 'block';
                            document.getElementById(object.name + "_errorlabel").textContent = 'Please enter a valid email address';
                            validation = false;
                        } else if (object.className === "email") {
                            email = value;
                        } else if (object.className === "emailConfirm") {
                            if (value !== email) {
                                document.getElementById(object.name).style.backgroundColor = 'rgb(255,0,0,0.6)';
                                document.getElementById(object.name + "_errorlabel").style.color = 'red';
                                document.getElementById(object.name + "_errorlabel").parentNode.style.display = 'block';
                                document.getElementById(object.name + "_errorlabel").textContent = 'Mismatched email addresses';
                                validation = false;
                            }
                        }
                    }
                }

                if (object.className !== "emailConfirm") {
                    itemData = { ...itemData, [object.className]: value };
                }

            } else if (object.type === "select") {
                let value = document.getElementById(object.name).nextSibling.value
                let name = object.label.toLowerCase();
                if (object.required === true && value.length === 0) {
                    document.getElementById(object.name).style.backgroundColor = 'rgb(255,0,0,0.6)';
                    document.getElementById(object.name + "_errorlabel").style.color = 'red';
                    document.getElementById(object.name + "_errorlabel").parentNode.style.display = 'block';
                    document.getElementById(object.name + "_errorlabel").textContent = 'This field cannot be empty';
                    validation = false;
                } else {
                    itemData = { ...itemData, [name]: value };
                }
            }
            else if (object.type === "text" && (object.className !== "name" || object.className !== "surname" || object.className !== "email" || object.className !== "emailConfirm")) {
                let value = document.getElementById(object.name).value;
                let name = object.label.toLowerCase();
                if (object.required === true && value.length === 0) {
                    document.getElementById(object.name).style.backgroundColor = 'rgb(255,0,0,0.6)';
                    document.getElementById(object.name + "_errorlabel").style.color = 'red';
                    document.getElementById(object.name + "_errorlabel").parentNode.style.display = 'block';
                    document.getElementById(object.name + "_errorlabel").textContent = 'This field cannot be empty';
                    validation = false;
                } else {
                    itemData = { ...itemData, [name]: value };
                }
            }
        }

        if (validation === true) {
            //replace + on email
            //itemData.email = itemData.email.replace("+", "%2B");
            registrationJSON.item = escapeSpecialCharFromJsonData(itemData);
            //registrationJSON.item = itemData;
            //console.log(registrationJSON);
            sendRegistrationOnQueue(registrationJSON);
            setGuiRegistrationStatus(true);
        }
    }

    return (
        <MercuryResponsivePage pageElements={guiComponentData}>
            <Box sx={{ flexGrow: 1 }} marginBottom={10}>
                <Typography variant="h3" component="h2" mt={5}>
                    {eventData.name}
                </Typography>
                <div style={{ display: openedRegistrations ? 'block' : 'none' }}>
                    <div style={{ display: guiRegistrationStatus ? 'none' : 'block' }}>
                        <Box display="flex" justifyContent="center" alignItems="center" padding={'15px'}>
                            <div dangerouslySetInnerHTML={{ __html: pageTemplateData.subtitle }} />
                        </Box>
                        <MercuryBasicFieldsForm userRegistrationData={pageTemplateData.formField} />
                        <Box display="flex" justifyContent="center" alignItems="center" padding={'15px'}>
                            <div dangerouslySetInnerHTML={{ __html: pageTemplateData.gdprInfo }} />
                        </Box>
                        <Box display="flex" justifyContent="center" alignItems="center">
                            <FormControlLabel control={<Checkbox onChange={e => onAccepted(e.target.checked)} />} label="I accept" />
                        </Box>
                        <Box display="flex" justifyContent="center" alignItems="center" mt={3} mb={8}>
                            <Button size="large" onClick={() => { sendRegistration() }} disabled={sendRegistrationDisabled} variant="contained"> submit </Button>
                        </Box>
                    </div>

                    <div style={{ display: guiRegistrationStatus ? 'block' : 'none' }}>
                        <Box display="flex" justifyContent="center" alignItems="center" padding={5}>
                            <div dangerouslySetInnerHTML={{ __html: pageTemplateData.confirmMessage }} />
                        </Box>
                    </div>
                </div>

                <div style={{ display: unopenedRegistrations ? 'block' : 'none' }}>
                    <Box display="flex" justifyContent="center" alignItems="center" padding={'100px'}>
                        <Typography variant="h6" component="h2">
                            We regret to inform you that event registration has not yet begun
                        </Typography>
                    </Box>
                </div>

                <div style={{ display: closedRegistrations ? 'block' : 'none' }}>
                    <Box display="flex" justifyContent="center" alignItems="center" padding={'100px'}>
                        <Typography variant="h6" component="h2">
                            We regret to inform you that event registration is now closed
                        </Typography>
                    </Box>
                </div>
            </Box>
        </MercuryResponsivePage>
    );
}

export default Home;
*/


import MercuryResponsivePage from "../components/MercuryResponsivePage";
import React from "react";
import { Typography, Box } from "@mui/material";
import Grid from '@mui/material/Grid';

import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { sendRegistrationOnQueue } from "../aws/lambda/DBManager";
import { useEffect } from "react";
import SurveyJSVisualizer from "../components/formvisualizer/SurveyJSVisualizer";

const Home = ({ guiComponentData, pageTemplateData, eventData }) => {
    const [sendRegistrationDisabled, setSendRegistrationDisabled] = React.useState(true);
    const [guiRegistrationStatus, setGuiRegistrationStatus] = React.useState(false);


    //cambio stato registrazioni
    const [openedRegistrations, setOpenedRegistrations] = React.useState(false);
    const [unopenedRegistrations, setUnopenedRegistrations] = React.useState(false);
    const [closedRegistrations, setClosedRegistrations] = React.useState(false);


    const onAccepted = (e) => {
        if (e === true) {
            setSendRegistrationDisabled(false);
        } else {
            setSendRegistrationDisabled(true);
        }
    }


    const sendRegistration = ()=> {

    }

    useEffect(() => {
        const intervalId = setInterval(() => {
            let currentDate = Date.now();

            //se la data corrente è maggiore della data inizio evento mostra la registrazioni
            if (currentDate >= eventData.startRegistrationDate && currentDate <= eventData.endRegistrationDate) {
                setOpenedRegistrations(true);
                setUnopenedRegistrations(false);
                setClosedRegistrations(false);
            } else if (currentDate < eventData.startRegistrationDate) { //viceversa mostra il messaggio di registrazioni non ancora aperte
                setOpenedRegistrations(false);
                setUnopenedRegistrations(true);
                setClosedRegistrations(false);
            }
            //se la data corrente è maggiore della fine dell'evento mostra messaggio chiusura registrazioni
            else if (currentDate > eventData.endRegistrationDate) {
                setOpenedRegistrations(false);
                setUnopenedRegistrations(false);
                setClosedRegistrations(true);
            }
        }, 1000);
        return () => { clearInterval(intervalId); };
    }, [eventData]);


    return (
        <MercuryResponsivePage pageElements={guiComponentData} hideFooter={true}>
        <Box sx={{ flexGrow: 1 }} marginBottom={10}>
            {/** 
            <Typography variant="h3" component="h2" mt={5}>
                {eventData.name}
            </Typography>
            */}
            <div style={{ display: openedRegistrations ? 'block' : 'none' }}>
                <div style={{ display: guiRegistrationStatus ? 'none' : 'block' }}>
                    {/** 
                    <Box display="flex" justifyContent="center" alignItems="center" padding={'15px'}>
                        <div dangerouslySetInnerHTML={{ __html: pageTemplateData.subtitle }} />
                    </Box>
                            {console.log(pageTemplateData.formField)}
                    <Box display="flex" justifyContent="center" alignItems="center" padding={'15px'}>
                        <div dangerouslySetInnerHTML={{ __html: pageTemplateData.gdprInfo }} />
                    </Box>
                    <Box display="flex" justifyContent="center" alignItems="center">
                        <FormControlLabel control={<Checkbox onChange={e => onAccepted(e.target.checked)} />} label="I accept" />
                    </Box>
                    <Box display="flex" justifyContent="center" alignItems="center" mt={3} mb={8}>
                        <Button size="large" onClick={() => { sendRegistration() }} disabled={sendRegistrationDisabled} variant="contained"> submit </Button>
                    </Box>
    */}
    
                    <SurveyJSVisualizer surveyJson={pageTemplateData.formField} eventID={eventData.id}/>
                </div>

                <div style={{ display: guiRegistrationStatus ? 'block' : 'none' }}>
                    <Box display="flex" justifyContent="center" alignItems="center" padding={5}>
                        {/** 
                        <div dangerouslySetInnerHTML={{ __html: pageTemplateData.confirmMessage }} />
                        */}
                    </Box>
                </div>
            </div>

            <div style={{ display: unopenedRegistrations ? 'block' : 'none' }}>
                <Box display="flex" justifyContent="center" alignItems="center" padding={'100px'}>
                <Box component="img" sx={{ /*height: '260px',*/ objectFit: 'contain', maxWidth: '100%' }}
                            src={guiComponentData.mercury_logo}
                        />
                    <Typography variant="h6" component="h2">
                        {pageTemplateData.confirmMessage}
                    </Typography>
                </Box>
            </div>

            <div style={{ display: closedRegistrations ? 'block' : 'none' }}>
                <Box display="flex" justifyContent="center" alignItems="center" padding={'100px'}>
                <Grid container justifyContent="center" alignItems="center"  >
                    <Grid item xs={12}>
                        <Box mt={'80px'} component="img" sx={{ /*height: '260px',*/ objectFit: 'contain', maxWidth: '100%' }}
                                    src={guiComponentData.logo}
                                />
                        </Grid>
                        <Grid mt={'80px'}item xs={12}>
                    <Typography variant="h4" component="h2">
                        {pageTemplateData.confirmMessage}
                    </Typography>
                    </Grid>
                    </Grid>
                </Box>
            </div>
        </Box>
    </MercuryResponsivePage>
    );
}

export default Home;