import configData from "../../config";


const sendRegistrationOnQueue = (requestBody) => {
    const requestOptions = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody)
    };

    return fetch(configData.APIGateway_SQSRegistrationUser, requestOptions)
        .then(response => response.json())
        .then(data => data.item)
        .catch(_ => console.log("Impossibile inviare i dati"));
}


const getItemFromDB = (itemType, id) => {
    const requestOptions = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            "command": "getItemFromDB",
            "itemType": itemType,
            "id": id
        })
    };

    return fetch(configData.APIGateway_MercuryStreamLambda, requestOptions)
        .then(response => response.json())
        .then(data => data.item)
        .catch(_ => console.log("Impossibile inviare i dati"));
}


const getImgFileFromBucketS3 = (filePath, fileName) => {
    const requestOptions = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            "command": "getImgFileFromBucketS3",
            "filePath": filePath,
            "fileName": fileName
        })
    };

    return fetch(configData.APIGateway_MercuryStreamLambda, requestOptions)
        .then(response => response.json())
        .then(data => data.item)
        .catch(_ => console.log("Impossibile inviare i dati"));
}


export { getItemFromDB, getImgFileFromBucketS3, sendRegistrationOnQueue };