/***************************** SYSTEM CONFIGURATION ***************************/

/***** ENV TEST: da commentare prima del commit su git *****/
/***** AMBIENTE TEST :: FRANCOFORTE *****/
//const process = { env: { REACT_APP_APIGATEWAY_REGISTRATION_QUEUE: "https://igpqb7jhq5.execute-api.eu-central-1.amazonaws.com/prod", REACT_APP_GIT_BRANCH: "dev", REACT_APP_COGNITO_USERPOOLID: "eu-central-1_jkSj5QTO9", REACT_APP_COGNITO_CLIENTID: "4nbcm7q798tjjant1n8u8dlone", REACT_APP_APIGATEWAY_LAMBDA: "https://efeawfb1db.execute-api.eu-central-1.amazonaws.com/MercuryStreamLambda", REACT_APP_S3_PUBLICBUCKET_URL: "mercurystream-public-storage-lw4226qo9kxnjt4bi6be2z5qn0zpl5xzw6.s3.amazonaws.com" } }
/***** AMBIENTE SANDONATO :: FRANCOFORTE *****/
//const process = { env: { REACT_APP_APIGATEWAY_REGISTRATION_QUEUE: "https://t81hx4k3jh.execute-api.eu-central-1.amazonaws.com/prod", REACT_APP_GIT_BRANCH: "dev", REACT_APP_COGNITO_USERPOOLID: "eu-central-1_nUSIp8Vu6", REACT_APP_COGNITO_CLIENTID: "4vi0pimqp15t8pi4iobs2paljn", REACT_APP_APIGATEWAY_LAMBDA: "https://3ptaorb5a2.execute-api.eu-central-1.amazonaws.com/MercuryStreamLambda", REACT_APP_S3_PUBLICBUCKET_URL: "mercurystream-public-storage-538g0nq3cy6i91xa15hyzpuq0yihpvidbz.s3.amazonaws.com" } }


const configData = {
    /******************** API GATEWAY ********************/
    APIGateway_MercuryStreamLambda: process.env.REACT_APP_APIGATEWAY_LAMBDA,
    APIGateway_SQSRegistrationUser: process.env.REACT_APP_APIGATEWAY_REGISTRATION_QUEUE
};


export default configData;