import React from "react";
import Grid from '@mui/material/Grid';
import { Box, Typography } from '@mui/material';


const Page404 = () => {
    return (
        <Box alignItems="center" justifyContent="center" sx={{ background: "rgb(248,249,252)" }}>
            <Box minHeight="100vh" mb={"-64px"} alignItems="center" justifyContent="center" display="flex" sx={{ background: "rgb(248,249,252)" }}>
                <Grid container columns={{ xs: 4, sm: 8, md: 12 }} justifyContent="center" alignItems="center">
                    <Grid item xs={6}>
                        <Box display="flex" height='450px' justifyContent="center" alignItems="center">
                            <Box component="img" sx={{ maxWidth: '80%' }}
                                src="/images/static/MERCURYSuite_DigitalEvents.png"
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box display="flex" height='450px' justifyContent="center" alignItems="center">
                            <Box component="img" sx={{ maxWidth: '80%' }}
                                src="/images/static/404.png"
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Box sx={{ padding: "8px" }}>
            <Typography color="text.secondary" align="center">
                            Copyright © Lantech Longwave S.p.A. - 2024
                        </Typography>
                        <Typography color="text.secondary" align="center">
                            www.lantechlongwave.it
                        </Typography>
            </Box>
        </Box>
    );
}

export default Page404;