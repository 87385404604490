import { createElement } from "react";
import { ElementFactory, Question, Serializer } from "survey-core";
import { SurveyQuestionElementBase, ReactQuestionFactory } from "survey-react-ui";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useStripe, useElements, PaymentElement, CardElement } from '@stripe/react-stripe-js';
import React, { useState } from 'react';


const CUSTOM_TYPE = "stripe-payments";




const CheckoutForm = () => {
    const stripe = useStripe();
    const elements = useElements();
  
    const [errorMessage, setErrorMessage] = useState(null);
  
    const handleSubmit = async (event) => {
      event.preventDefault();
  
      if (elements == null) {
        return;
      }
  
      // Trigger form validation and wallet collection
      const {error: submitError} = await elements.submit();
      if (submitError) {
        // Show error to your customer
        setErrorMessage(submitError.message);
        return;
      }
  
      // Create the PaymentIntent and obtain clientSecret from your server endpoint
      const res = await fetch('/create-intent', {
        method: 'POST',
      });
  
      const {client_secret: clientSecret} = await res.json();
  
      const {error} = await stripe.confirmPayment({
        //`Elements` instance that was used to create the Payment Element
        elements,
        clientSecret,
        confirmParams: {
          return_url: 'https://example.com/order/123/complete',
        },
      });
  
      if (error) {
        // This point will only be reached if there is an immediate error when
        // confirming the payment. Show error to your customer (for example, payment
        // details incomplete)
        setErrorMessage(error.message);
      } else {
        // Your customer will be redirected to your `return_url`. For some payment
        // methods like iDEAL, your customer will be redirected to an intermediate
        // site first to authorize the payment, then redirected to the `return_url`.
      }
    };
  
    return (
      <form onSubmit={handleSubmit}>
        <PaymentElement />
        <button type="submit" disabled={!stripe || !elements}>
          Pay
        </button>
        {/* Show error message to your customers */}
        {errorMessage && <div>{errorMessage}</div>}
      </form>
    );
  };
  
  const stripePromise = loadStripe('pk_test_6pRNASCoBOKtIshFeQd4XMUh');
  
  const options = {
    mode: 'payment',
    amount: 1099,
    currency: 'usd',
    // Fully customizable with appearance API.
    appearance: {
      /*...*/
    },
  };
  


const Stripe = () => {
  return (
/** 
    <div className="page-container">
      <div className="center-container">
        <div className="stripe-container">
        <Elements stripe={stripePromise} options={options}>
      <CheckoutForm />
    </Elements>
        </div>
      </div>
    </div>
   */

   <Elements stripe={stripePromise} options={options}>
   <CheckoutForm />
 </Elements>
  );
};



// A model for the new question type
export class QuestionStripePaymentsModel extends Question {
  getType() {
    return CUSTOM_TYPE;
  }
}

// Register `QuestionColorPickerModel` as a model for the `color-picker` type
export function stripePayments() {
  ElementFactory.Instance.registerElement("Stripe payments", (name) => {
    return new QuestionStripePaymentsModel(name);
  });
}


// Add question type metadata for further serialization into JSON
Serializer.addClass(
  CUSTOM_TYPE,
  [{
    
  }],
  function () {
    return new QuestionStripePaymentsModel("");
  },
  "question"
);

// A class that renders questions of the new type in the UI
export class SurveyStripePayments extends SurveyQuestionElementBase {
  constructor(props) {
    super(props);
    this.state = { value: this.question.value };
  }
  get question() {
    return this.questionBase;
  }
 





  renderElement() {
    return (
      <div style={this.style}>
        <Stripe/>
      </div>
    );
  }
}

// Register `SurveyQuestionColorPicker` as a class that renders `color-picker` questions 
ReactQuestionFactory.Instance.registerQuestion(CUSTOM_TYPE, (props) => {
  return createElement(SurveyStripePayments, props);
});


export default stripePayments;
