import React, { useState, useEffect } from "react"
import { useSearchParams } from "react-router-dom";
import Page404 from "../pages/Page404";
import { getItemFromDB, getImgFileFromBucketS3 } from "../aws/lambda/DBManager";
import { Loading, LoadingLogo } from "../pages/Loading";


const RequireEventID = ({ children, setTemplateData, setEventData, templateGuiElements, setTemplateGuiElements }) => {

    const [showError, setShowError] = useState(false);
    const [showLoading, setShowLoading] = useState(true);
    const [showLoadingLogo, setShowLoadingLogo] = useState(false);
    const [showChildren, setShowChildren] = useState(false);
    const [searchParams] = useSearchParams();


    //get image logo
    async function getLogoImg(templatePageID, templateData, setTemplateData) {
        await getImgFileFromBucketS3("pdrTemplate/" + templatePageID + "/", "logo.png").then(data => {
            if (data.statusCode === undefined) {
                setTemplateData(templateData => ({ ...templateData, logo: data }))
                setShowLoading(false);
                setShowLoadingLogo(true);
            }
        });
    }

    //get image banner
    async function getImgPdrTemplate(templatePageID, templateData, setTemplateData) {
        await getImgFileFromBucketS3("pdrTemplate/" + templatePageID + "/", "banner.png").then(data => {
            if (data.statusCode === undefined) {
                setTemplateData(templateData => ({ ...templateData, banner: data }))
            }
        });
    }


    //http://localhost:3000/?event=xxx
    useEffect((session) => {
        try {
            if (searchParams.get('event').length > 0) {
                getItemFromDB("event", searchParams.get('event')).then(data => {
                    let item = data.Item;
                    if (item.pageTemplate.registrationPage.length > 0) {
                        (async () => {
                            setEventData(item);
                            let templateID = item.pageTemplate.registrationPage;
                            if (templateID === undefined) {
                                setShowError(true);
                                setShowLoading(false);
                                setShowChildren(false);
                                setShowLoadingLogo(false);
                            }
                            else
                                /**get pdr data */
                                await getLogoImg(templateID, templateGuiElements, setTemplateGuiElements);

                            getItemFromDB("template_pdr", templateID).then(data => {
                                (async () => {
                                    setTemplateData(data.Item);
                                    await getImgPdrTemplate(templateID, templateGuiElements, setTemplateGuiElements);
                                    setShowError(false);
                                    setShowLoading(false);
                                    setShowLoadingLogo(false);
                                    setShowChildren(true);
                                })();

                            });

                        })();
                    } else {
                        setShowError(true);
                        setShowLoading(false);
                        setShowLoadingLogo(false);
                        setShowChildren(false);
                    }
                });
            }
            else {
                setShowError(true);
                setShowLoading(false);
                setShowChildren(false);
                setShowLoadingLogo(false);
            };
        } catch (e) {
            setShowError(true);
            setShowLoading(false);
            setShowChildren(false);
            setShowLoadingLogo(false);
        }

    }, []);

    return (
        <>
            <div style={{ display: showLoading ? 'block' : 'none' }}>
                <Loading hideDigitalEventsLogo={true} hideFooter={true}/>
            </div>
            <div style={{ display: showLoadingLogo ? 'block' : 'none' }}>
                <LoadingLogo imgLogo={templateGuiElements.logo} hideFooter={true}/>
            </div>
            <div style={{ display: showError ? 'block' : 'none' }}>
                <Page404 />
            </div>
            <div style={{ display: showChildren ? 'block' : 'none' }}>
                {children}
            </div>
        </>
    );
};

export default RequireEventID;