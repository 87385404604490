import React from "react";
import Grid from '@mui/material/Grid';
import { Box, AppBar, Toolbar } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

/*
const Footer = () => {
    return (
        <AppBar elevation={0} position="relative" color="primary" sx={{ top: 'auto', bottom: 0, background: 'transparent' }}>
            <Toolbar sx={{ justifyContent: "center" }}>
                <Box sx={{ padding: "8px" }}>
                    <Typography color="text.secondary" align="center">
                        Copyright © Lantech Longwave S.p.A. - 2024
                    </Typography>
                    <Typography color="text.secondary" align="center">
                        www.lantechlongwave.it
                    </Typography>
                </Box>
            </Toolbar>
        </AppBar>
    );

}
*/


const Footer = () => {
    return (
        <AppBar elevation={0} sx={{ background: "rgb(248,249,252)", top: 'auto', bottom: 0 }} >
            <Toolbar sx={{ justifyContent: "center" }}>
                <Box component="img" height="48px" sx={{ padding: '8px' }}
                     src="/images/static/MERCURYSuite_DigitalEvents.png"
                />
            </Toolbar>
        </AppBar>
    );
}


const Loading = ({hideDigitalEventsLogo, hideFooter}) => {
    return (
        <Box display="flex" justifyContent="center" flexDirection="column" minHeight="100vh" sx={{ background: "rgb(248,249,252)" }}>
            <Box sx={{ padding: "22px" }} flex={1} justifyContent="center" flexDirection="column" display="flex">
                <Grid container justifyContent="center" alignItems="center"  >
                {!hideDigitalEventsLogo && 
                    <Grid item xs={12}>
                        <Box component="img" sx={{ /*height: '260px',*/ objectFit: 'contain', maxWidth: '50%' }}
                            src="/images/static/MERCURYSuite_DigitalEvents.png"
                        />
                    </Grid>
                    }
                  
                    <Grid item xs={12}>
                        <Box mt={6}>
                            <CircularProgress size="3rem" />
                        </Box>
                    </Grid>
                </Grid>
            </Box>

            {!hideFooter && <Footer/>}
        </Box>

    );
}




const LoadingLogo = ({ imgLogo, hideFooter }) => {
    return (
        <Box display="flex" justifyContent="center" flexDirection="column" minHeight="100vh" sx={{ background: "rgb(248,249,252)" }}>
            <Box sx={{ padding: "22px" }} flex={1} justifyContent="center" flexDirection="column" display="flex">
                <Grid container justifyContent="center" alignItems="center"  >
                    <Grid item xs={12}>
                       <Box component="img" sx={{ /*height: '260px',*/ objectFit: 'contain', maxWidth: '40%' }}
                            src={imgLogo}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Box mt={6}>
                            <CircularProgress size="3rem" />
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            {!hideFooter && <Footer/>}
        </Box>

    );
}

export { Loading, LoadingLogo };